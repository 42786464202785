<template>
  <v-card class="mx-auto" :disabled="table_loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                table_loading ? '' : `(${table_data.length})`
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="brown darken-3"
                class="ml-1"
                v-on="on"
                x-small
                dark
                fab
                :to="{
                  name: `caja_medicos.comprobantes_cobro`,
                }"
              >
                <v-icon>mdi-cash-refund</v-icon>
              </v-btn>
            </template>
            <span v-text="'Comprobantes cobro'" />
          </v-tooltip>
          <v-tooltip
            v-if="login.permissions.rs_services_insured_bills.create"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="success"
                class="ml-1"
                v-on="on"
                x-small
                dark
                fab
                :to="{
                  name: `${page_route}.agregar`,
                }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span v-text="'Agregar'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="5">
          <v-select
            label="Estado"
            v-model="filter"
            :items="filter_options"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            @change="tableFilters"
          />
        </v-col>
        <v-col cols="12" xs="12" md="7">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
      <v-row v-if="filter == 3" dense>
        <v-col cols="12" xs="12" md="3">
          <v-text-field
            label="ID/SM"
            v-model="table_search_options.enrollment"
            type="text"
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-text-field
            label="Nombre"
            v-model="table_search_options.name"
            type="text"
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-text-field
            label="A. paterno"
            v-model="table_search_options.first_surname"
            type="text"
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-text-field
            label="A. materno"
            v-model="table_search_options.second_surname"
            type="text"
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-autocomplete
            label="Aseguradora"
            v-model="table_search_options.insurance_id"
            :items="insurances"
            item-value="id"
            :item-text="(item) => item.name"
            dense
            :loading="insurances_loading"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker
            label="F. cobro Inicio"
            :model.sync="table_search_options.start_date"
            clean
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker
            label="F. cobro Final"
            :model.sync="table_search_options.end_date"
            clean
          />
        </v-col>
        <v-col cols="12" xs="12" md="12">
          <v-btn tile block color="info" @click="getTableData">
            <v-icon left> mdi-note-search </v-icon>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="table_headers"
            :search="table_search"
            :items="table_data"
            :loading="table_loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.index="{ item }">
              <b v-text="item.index" />
            </template>
            <template v-slot:item.doctor.created_by_info="{ item }">
              <v-icon v-if="item.doctor.created_by_info" color="warning" small>
                mdi-check
              </v-icon>
            </template>
            <template v-slot:item.amount="{ item }">
              {{
                numberFormat(item.amount) +
                (item.rs_service_insured_bill ? "" : "*")
              }}
            </template>
            <template v-slot:item.doctor_name="{ item }">
              <span v-text="item.doctor_name" />
              <div v-if="item.doctor_prerevision">
                <i>
                  <small v-text="'Cuenta bancaria en revisión*'" />
                </i>
              </div>
            </template>
            <template
              v-slot:item.rs_service_insured_bill.stamp_folio="{ item }"
            >
              <span v-if="item.rs_service_insured_bill">
                <span v-if="item.rs_service_insured_bill.stamp_folio">
                  {{ item.rs_service_insured_bill.stamp_folio }}
                </span>
                <span v-else> - </span>
              </span>
            </template>
            <template v-slot:item.rs_service_bill.paid_amount="{ item }">
              {{
                item.rs_service_bill && item.rs_service_bill.paid_amount
                  ? numberFormat(item.rs_service_bill.paid_amount)
                  : ""
              }}
            </template>
            <template v-slot:item.rs_service_insured_bill.url_ticket="{ item }">
              <v-icon
                small
                :color="
                  item.rs_service_insured_bill
                    ? item.rs_service_insured_bill.url_ticket
                      ? 'success'
                      : 'warning'
                    : ''
                "
              >
                {{
                  item.rs_service_insured_bill
                    ? item.rs_service_insured_bill.url_ticket
                      ? "mdi-check"
                      : "mdi-alert"
                    : ""
                }}
              </v-icon>
            </template>
            <template
              v-slot:item.rs_service_no_payment_type.no_payment_types="{ item }"
            >
              <div v-if="item.rs_service_no_payment_type" class="red--text">
                {{ item.rs_service_no_payment_type.no_payment_types }}
              </div>
            </template>
            <template v-slot:item.valid="{ item }">
              <v-icon
                v-if="item.valid != null"
                small
                :color="item.valid ? 'success' : 'warning'"
              >
                {{ item.valid ? "mdi-check" : "mdi-alert" }}
              </v-icon>
              <span v-else v-text="'-'" />
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    color="info"
                    :to="{
                      name: `${page_route}.detalle`,
                      params: { rs_service_id: item.id },
                    }"
                    v-on="on"
                    x-small
                    dark
                    fab
                  >
                    <v-icon> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Detalle'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  login.permissions.rs_services_insured_bills.create &&
                  !item.rs_service_insured_bill &&
                  !item.consulting_room
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    color="purple darken-2"
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click="consultingRoomDialog(item)"
                  >
                    <v-icon> mdi-hospital-building </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Asignar Consultorio | Proveedor'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  !item.rs_service_insured_bill &&
                  item.consulting_room &&
                  item.consulting_room.provider &&
                  !item.consulting_room.provider.particular_payment
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-1" color="error" v-on="on" x-small dark fab>
                    <v-icon> mdi-alert </v-icon>
                  </v-btn>
                </template>
                <span
                  v-text="
                    'No se puede realizar cobro. Porcentaje cobro particulares no configurado en Provedoor'
                  "
                />
              </v-tooltip>

              <v-tooltip
                v-if="
                  login.permissions.rs_services_insured_bills.create &&
                  !item.rs_service_insured_bill &&
                  item.consulting_room &&
                  item.consulting_room.provider &&
                  item.consulting_room.provider.particular_payment
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    color="orange darken-2"
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click="paymentDialog(item)"
                  >
                    <v-icon> mdi-currency-usd </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Cobrar'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  login.permissions.rs_services_insured_bills.create &&
                  item.rs_service_insured_bill &&
                  item.rs_service_insured_bill.stamp_folio &&
                  !item.rs_service_bill
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    color="amber darken-2"
                    v-on="on"
                    x-small
                    dark
                    fab
                    :to="{
                      name: 'caja_medicos.documentacion',
                      params: { rs_service_id: item.id },
                    }"
                  >
                    <v-icon> mdi-file-code </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Cargar documentación'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  login.permissions.rs_services_insured_bills.create &&
                  item.rs_service_insured_bill &&
                  !item.rs_service_insured_bill.url_ticket
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    color="orange darken-3"
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click.prevent="
                      ticketDialog(
                        item.folio_full,
                        item.folio,
                        item.rs_service_insured_bill
                      )
                    "
                  >
                    <v-icon> mdi-receipt-text-plus </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Cargar recibo de pago'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  login.permissions.rs_services_insured_bills.create &&
                  !item.doctor.bank_account_pdf &&
                  !item.doctor_prerevision &&
                  !item.doctor_revision // Cambiar que no tengan revisiones existentes
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    :color="
                      item.doctor_prerevision_rejected
                        ? 'error'
                        : 'amber darken-3'
                    "
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click.prevent="doctorPrerevisionDialog(item)"
                  >
                    <v-icon> mdi-file-sign </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Cargar cuenta bancaria'" />
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    :color="
                      item.rs_service_notes_total > 0
                        ? 'pink darken-1'
                        : 'grey lighten-1'
                    "
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click.prevent="noteDialog(item)"
                  >
                    <v-icon> mdi-note-text </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Nota(s) administrativa(s)'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="service_payment_dialog"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card
        tile
        :disabled="service_payment_dialog_loading"
        :loading="service_payment_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="service_payment_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="service_payment_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="`SERVICIO | ${service_payment_data.folio_full}`"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="F. creación"
                        :value="service_payment_data.created_at"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Asegurado"
                        :value="service_payment_data.insured_full_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Médico"
                        :value="service_payment_data.doctor_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Especialidad"
                        :value="service_payment_data.specialty_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Consult. | Hosp."
                        :value="service_payment_data.provider_trade_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Monto"
                        :value="
                          numberFormat(service_payment_data.benefit_amount)
                        "
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'COBRO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_service_payment_data"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          label="Paciente | C. electrónico*"
                          v-model="service_payment_data.email"
                          type="text"
                          maxlength="80"
                          dense
                          counter
                          :rules="rules.email"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Monto*"
                          v-model="service_payment_data.pay_amount"
                          type="number"
                          :rules="rules.required"
                          dense
                          @input="paymentDialogInsurancePayAmount"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          login.permissions.rs_services_insured_bills.update
                        "
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <v-text-field
                          label="Monto a cobrar*"
                          v-model="service_payment_data.insurance_pay_amount"
                          type="number"
                          :rules="rules.required"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-switch
                          class="mt-1 pt-0"
                          :label="'Factura'"
                          v-model="service_payment_data.bill"
                          dense
                          @change="billClean"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="service_payment_data.bill" dense>
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          label="Razón social*"
                          v-model="service_payment_data.legal_name"
                          type="text"
                          maxlength="100"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="RFC*"
                          v-model="service_payment_data.code_tax"
                          type="text"
                          maxlength="13"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Código postal*"
                          v-model="service_payment_data.legal_zip"
                          type="text"
                          maxlength="5"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Régimen*"
                          v-model="service_payment_data.fiscal_regime_id"
                          :items="fiscal_regimes"
                          item-value="id"
                          :item-text="(v) => `${v.code} | ${v.fiscal_regime}`"
                          :loading="fiscal_regimes_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Uso CFDI*"
                          v-model="service_payment_data.fiscal_use_id"
                          :items="fiscal_uses"
                          item-value="id"
                          :item-text="(v) => `${v.code} | ${v.fiscal_use}`"
                          :loading="fiscal_uses_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Forma de pago*"
                          v-model="
                            service_payment_data.fiscal_method_payment_id
                          "
                          :items="fiscal_method_payments"
                          item-value="id"
                          :item-text="(v) => v.fiscal_method_payment"
                          :loading="fiscal_method_payments_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          service_payment_data.fiscal_method_payment_id == 4 ||
                          service_payment_data.fiscal_method_payment_id == 18
                        "
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <v-text-field
                          label="Núm. de aprobación*"
                          v-model="service_payment_data.approval_number"
                          type="text"
                          maxlength="10"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          service_payment_data.fiscal_method_payment_id == 4 ||
                          service_payment_data.fiscal_method_payment_id == 18
                        "
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <v-text-field
                          label="Tarjeta (4 últ. dig.)*"
                          v-model="service_payment_data.card"
                          type="text"
                          maxlength="4"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          service_payment_data.fiscal_method_payment_id == 1 ||
                          service_payment_data.fiscal_method_payment_id == 3
                        "
                        cols="12"
                        xs="12"
                        md="6"
                      >
                        <v-text-field
                          label="Referencia*"
                          v-model="service_payment_data.reference"
                          type="text"
                          maxlength="20"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="service_payment_data.observation"
                          label="Observación"
                          dense
                          rows="1"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="success"
                          @click.prevent="paymentSubmit"
                          small
                        >
                          <v-icon left> mdi-currency-usd </v-icon>
                          Generar cobro
                          {{ service_payment_data.bill ? " con factura" : "" }}
                          por
                          {{
                            numberFormat(
                              service_payment_data.insurance_pay_amount
                            )
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="consulting_room_dialog"
      scrollable
      persistent
      max-width="600"
    >
      <v-card
        tile
        :disabled="consulting_room_dialog_loading"
        :loading="consulting_room_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>
            SERVICIO | FOLIO
            {{ consulting_room_data ? consulting_room_data.folio_full : "" }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="consulting_room_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="consulting_room_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'MÉDICO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="consulting_room_form"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Nombre"
                          :value="consulting_room_data.doctor_name"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Especialidad"
                          :value="
                            consulting_room_data.specialty_type.specialty_type
                          "
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Consultorio | Proveedor*"
                          v-model="consulting_room_data.provider_id"
                          :items="providers"
                          item-value="id"
                          :item-text="(v) => v.trade_name"
                          :loading="providers_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="success"
                          @click.prevent="consultingRoomSave"
                          small
                        >
                          ACEPTAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ticket_dialog" scrollable persistent max-width="600">
      <v-card
        tile
        :disabled="ticket_dialog_loading"
        :loading="ticket_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>
            SERVICIO | FOLIO
            {{ ticket_data ? ticket_data.folio_full : "" }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="ticket_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="ticket_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `COBRO | ${ticket_data.folio}  | ${
                          !ticket_data.rs_service_insured_bill.stamp_id
                            ? 'SIN'
                            : 'CON'
                        } FACTURA`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form v-on:submit.prevent ref="ticket_form" lazy-validation>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="F. cobro"
                          :value="
                            ticket_data.rs_service_insured_bill.created_at
                          "
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Monto"
                          :value="
                            ticket_data.rs_service_insured_bill.pay_amount
                          "
                        />
                      </v-col>
                      <v-col cols="12" />
                      <v-col cols="12">
                        <v-file-input
                          label="Recibo de pago*"
                          v-model="ticket_data.document_ticket_0"
                          accept="image/*,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                          :rules="rules.required"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="orange darken-3"
                          dark
                          @click.prevent="ticketSubmit"
                          small
                        >
                          CARGAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="doctor_prerevision_dialog"
      scrollable
      persistent
      max-width="900"
    >
      <v-card
        tile
        :disabled="doctor_prerevision_dialog_loading"
        :loading="doctor_prerevision_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> MÉDICO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_prerevision_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_prerevision_data">
          <v-row>
            <v-col cols="12" />
            <v-col
              v-if="doctor_prerevision_data.doctor_prerevision_rejected"
              cols="12"
            >
              <v-alert type="error" class="mb-0" dense>
                <v-row dense>
                  <v-col cols="12">
                    <b>CARGA PREVIA RECHAZADA</b>
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Banco"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected.bank
                          .bank
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Número de cuenta"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected
                          .account_number
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="CLABE"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected
                          .clabe
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <span
                      class="text-caption font-weight-bold"
                      v-text="'Cuenta bancaria (PDF)'"
                    />
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :href="
                            url_documents +
                            '/doctors/' +
                            doctor_prerevision_data.doctor_prerevision_rejected
                              .bank_account_pdf
                          "
                          target="_blank"
                        >
                          <v-icon small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Ver'" />
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12">
                    <ViewData
                      label="Observación"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected
                          .observation
                      "
                    />
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'GENERAL'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Nombre"
                        :value="`IDM ${doctor_prerevision_data.doctor_id} | ${doctor_prerevision_data.doctor_name}`"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Especialidad"
                        :value="doctor_prerevision_data.specialty_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Consult. | Hosp."
                        :value="doctor_prerevision_data.provider_trade_name"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'BANCO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="doctor_prerevision_form"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          label="Banco*"
                          v-model="doctor_prerevision_data.bank_id"
                          :items="banks"
                          item-value="id"
                          :item-text="(v) => v.bank"
                          :loading="banks_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Número de cuenta*"
                          v-model="doctor_prerevision_data.account_number"
                          type="text"
                          maxlength="15"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="CLABE*"
                          v-model="doctor_prerevision_data.clabe"
                          type="text"
                          maxlength="18"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          label="Cuenta bancaria (PDF)*"
                          v-model="
                            doctor_prerevision_data.document_bank_account_0
                          "
                          accept=".pdf"
                          :rules="rules.required"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="amber darken-3"
                          dark
                          @click.prevent="doctorPrerevisionSubmit"
                          small
                        >
                          CARGAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="note_dialog" scrollable persistent max-width="900">
      <v-card
        tile
        :disabled="note_dialog_loading"
        :loading="note_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> NOTA(S) ADMINISTRATIVA(S) </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="note_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="note_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="`SERVICIO | FOLIO ${note_data.folio_full}`"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Médico"
                        :value="
                          'IDM ' +
                          note_data.doctor_id +
                          ' | ' +
                          note_data.doctor_name
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Asegurado"
                        :value="note_data.insured_full_name"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col v-if="rs_service_notes" cols="12">
                      <v-row
                        v-for="(rs_service_note, i) in rs_service_notes"
                        :key="i"
                        dense
                      >
                        <v-col cols="12">
                          <ViewData
                            :label="`${i + 1}. Nota administrativa`"
                            :value="rs_service_note.rs_service_note"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                          <ViewData
                            label="Usuario"
                            :value="rs_service_note.created_by.email"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                          <ViewData
                            label="F. creación"
                            :value="rs_service_note.created_at"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-divider />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12">
                      <v-form
                        v-on:submit.prevent
                        ref="note_form"
                        lazy-validation
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <v-textarea
                              v-model="note_data.rs_service_note"
                              label="Nueva nota administrativa*"
                              dense
                              rows="2"
                              :rules="rules.required"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-btn
                              block
                              color="pink darken-1"
                              dark
                              @click.prevent="noteSubmit"
                              small
                            >
                              AGREGAR
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  dateTimeNow,
  msgAlert,
  msgConfirm,
  rules,
  toFormData,
  URL_DOCUMENTS,
} from "../../control";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    DatePicker,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      page_route: "caja_medicos",
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      table_loading: false,
      table_data: [],
      table_search: "",
      table_search_options: {
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        insurance_id: "",
        start_date: "",
        end_date: "",
      },
      table_headers: [
        { text: "#", filterable: true, value: "index" },
        { text: "Folio", filterable: true, value: "folio_full" },
        { text: "F. Creación", filterable: true, value: "created_at" },
        { text: "Asegurado", filterable: true, value: "insured_full_name" },
        { text: "IDM", filterable: true, value: "doctor_id" },
        { text: "Médico", filterable: true, value: "doctor_name" },
        {
          text: "Desde INFO",
          filterable: true,
          value: "doctor.created_by_info",
        },
        {
          text: "Especialidad",
          filterable: true,
          value: "specialty_type.specialty_type",
        },
        {
          text: "Consult. | Hosp.",
          filterable: true,
          value: "provider_trade_name",
        },
        { text: "Monto", filterable: true, value: "amount" },
        {
          text: "Cobrado por",
          filterable: true,
          value: "payment_user_created",
        },
        { text: "Cobro ID", filterable: true, value: "folio" },
        {
          text: "Factura",
          filterable: true,
          value: "rs_service_insured_bill.stamp_folio",
        },
        { text: "F. cobro", filterable: true, value: "payment_date" },
        {
          text: "Recibo pago",
          filterable: true,
          value: "rs_service_insured_bill.url_ticket",
        },
        { text: "Doc. ID", filterable: true, value: "rs_service_bill.folio" },
        {
          text: "Promesa pago",
          filterable: true,
          value: "rs_service_bill.pay_date",
        },
        {
          text: "F. pago",
          filterable: true,
          value: "rs_service_bill.paid_date",
        },
        {
          text: "M. pagado",
          filterable: true,
          value: "rs_service_bill.paid_amount",
        },
        {
          text: "Motivo de no pago",
          filterable: true,
          value: "rs_service_no_payment_type.no_payment_types",
        },
        {
          text: "Conciliado",
          filterable: true,
          value: "valid",
        },
        { text: "", value: "action", sortable: false, width: "245px" },
      ],
      insurances: [],
      insurances_loading: true,
      filter: 1,
      filter_options: [
        { id: 1, name: "PENDIENTES" },
        { id: 2, name: "COBRADOS SIN PAGO AL MÉDICO" },
        { id: 3, name: "TERMINADOS" },
      ],
      service_payment_data: null,
      service_payment_dialog: false,
      service_payment_dialog_loading: false,
      fiscal_regimes: [],
      fiscal_regimes_loading: true,
      fiscal_uses: [],
      fiscal_uses_loading: true,
      fiscal_method_payments: [],
      fiscal_method_payments_loading: true,
      rules: rules(),
      providers: [],
      providers_loading: false,
      consulting_room_data: null,
      consulting_room_dialog: false,
      consulting_room_dialog_loading: false,
      ticket_data: null,
      ticket_dialog: false,
      ticket_dialog_loading: false,
      doctor_prerevision_data: null,
      doctor_prerevision_dialog: false,
      doctor_prerevision_dialog_loading: false,
      banks: [],
      banks_loading: true,
      note_data: null,
      note_dialog: false,
      note_dialog_loading: false,
      rs_service_notes: null,
      item: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      this.table_loading = true;
      this.table_data = [];
      let params = "?filter=" + this.filter;

      if (this.filter == 3) {
        params +=
          "&enrollment=" +
          this.table_search_options.enrollment +
          "&name=" +
          this.table_search_options.name +
          "&first_surname=" +
          this.table_search_options.first_surname +
          "&second_surname=" +
          this.table_search_options.second_surname +
          "&insurance_id=" +
          this.table_search_options.insurance_id +
          "&start_date=" +
          this.table_search_options.start_date +
          "&end_date=" +
          this.table_search_options.end_date +
          "&operator=!=";
      }

      Axios.get(
        `${URL_API}/rss/services/particulars${params}`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.table_data = resp.data.data;
        this.table_loading = false;
      });
    },
    tableFilters() {
      if (this.filter == 3) {
        this.table_data = [];
      } else {
        this.getTableData();
      }
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    paymentDialog(service) {
      this.service_payment_data = {
        id: null,
        active: true,
        pay_amount: parseFloat(service.amount),
        insurance_pay_amount: 0,
        bill: false,
        legal_name: service.insured_full_name,
        code_tax: "",
        legal_zip: "",
        fiscal_regime_id: null,
        fiscal_use_id: null,
        fiscal_method_payment_id: null,
        rs_service_id: service.id,
        folio_full: service.folio_full,
        benefit_amount: service.amount,
        specialty_type: service.specialty_type.specialty_type,
        doctor_name: service.doctor_name,
        email: service.email,
        particular_payment: parseFloat(
          service.consulting_room.provider.particular_payment
        ),
        observation: null,
        approval_number: "",
        card: "",
        insured_full_name: service.insured_full_name,
        created_at: service.created_at,
        provider_trade_name: service.provider_trade_name,
        reference: "",
        doctor_id: service.doctor_id,
      };

      this.paymentDialogInsurancePayAmount();

      this.service_payment_dialog_loading = false;
      this.service_payment_dialog = true;
    },
    paymentDialogInsurancePayAmount() {
      let amount =
        this.service_payment_data.pay_amount *
        (1 + this.service_payment_data.particular_payment / 100);

      amount = amount / 100;
      amount = Math.ceil(amount);
      amount = amount * 100;

      this.service_payment_data.insurance_pay_amount = amount;
    },
    paymentSubmit() {
      if (this.$refs.form_service_payment_data.validate()) {
        if (parseFloat(this.service_payment_data.pay_amount) >= 0.01) {
          if (
            !this.service_payment_data.bill ||
            (this.service_payment_data.bill &&
              this.service_payment_data.code_tax != "XAXX010101000")
          ) {
            this.$swal
              .fire(
                msgConfirm(
                  `¿Confirma el cobro${
                    this.service_payment_data.bill
                      ? " con emisión de factura"
                      : ""
                  } por ${this.numberFormat(
                    this.service_payment_data.insurance_pay_amount
                  )}?`
                )
              )
              .then((resp) => {
                if (resp.isConfirmed) {
                  this.service_payment_dialog_loading = true;

                  Axios.post(
                    `${URL_API}/rss/services/insured/bills`,
                    this.service_payment_data,
                    headersToken(this.login.token)
                  ).then((resp) => {
                    this.$swal.fire(
                      msgAlert(
                        resp.data.success ? "success" : "error",
                        resp.data.message
                      )
                    );

                    if (resp.data.success) {
                      this.service_payment_dialog = false;
                      this.getTableData();

                      let folio_full = resp.data.folio_full;
                      let folio = resp.data.folio;
                      let rs_service_insured_bill =
                        resp.data.rs_service_insured_bill;

                      this.$swal
                        .fire(msgConfirm(`¿Cargar recibo de pago?`))
                        .then((resp) => {
                          if (resp.isConfirmed) {
                            this.ticketDialog(
                              folio_full,
                              folio,
                              rs_service_insured_bill
                            );
                          }
                        });
                    } else {
                      console.log(resp.data.message);
                    }

                    this.service_payment_dialog_loading = false;
                  });
                }
              });
          } else {
            this.$swal.fire(
              msgAlert(
                "error",
                "No se puede utilizar el RFC XAXX010101000 para facturar"
              )
            );
          }
        } else {
          this.$swal.fire(
            msgAlert("error", "Para continuar el monto debe ser mayor a $0.01")
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    consultingRoomDialog(item) {
      this.consulting_room_data = item;
      this.consulting_room_data.provider_id = null;
      this.consulting_room_dialog_loading = false;
      this.consulting_room_dialog = true;
    },
    consultingRoomSave() {
      if (this.$refs.consulting_room_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma actualizar la información?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.consulting_room_dialog_loading = true;

              Axios.post(
                `${URL_API}/doctors/consulting/room`,
                {
                  doctor_id: this.consulting_room_data.doctor_id,
                  provider_id: this.consulting_room_data.provider_id,
                },
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.consulting_room_dialog = false;
                  this.getTableData();
                } else {
                  console.log(resp.data.message);
                }

                this.consulting_room_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    ticketDialog(folio_full, folio, rs_service_insured_bill) {
      this.ticket_data = {
        id: rs_service_insured_bill.id,
        document_ticket_0: null,
        folio_full: folio_full,
        folio: folio,
        rs_service_insured_bill: rs_service_insured_bill,
      };
      this.ticket_dialog_loading = false;
      this.ticket_dialog = true;
    },
    ticketSubmit() {
      if (this.$refs.ticket_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma cargar el recibo de pago?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.ticket_dialog_loading = true;
              Axios.post(
                `${URL_API}/bills/insurance/ticket`,
                toFormData(this.ticket_data),
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.ticket_dialog = false;
                  this.getTableData();
                } else {
                  console.log(resp.data.message);
                }

                this.ticket_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    doctorPrerevisionDialog(item) {
      this.doctor_prerevision_data = {
        id: null,
        active: true,
        bank_id: null,
        account_number: "",
        clabe: "",
        document_bank_account_0: null,
        doctor_id: item.doctor_id,
        doctor_name: item.doctor_name,
        specialty_type: item.specialty_type.specialty_type,
        provider_trade_name: item.provider_trade_name,
        doctor_prerevision_rejected: item.doctor_prerevision_rejected,
        doctor_prerevision_rejected_id: item.doctor_prerevision_rejected
          ? item.doctor_prerevision_rejected.id
          : null,
      };
      this.doctor_prerevision_dialog_loading = false;
      this.doctor_prerevision_dialog = true;
    },
    doctorPrerevisionSubmit() {
      if (this.$refs.doctor_prerevision_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma cargar el recibo de pago?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.doctor_prerevision_dialog_loading = true;
              Axios.post(
                `${URL_API}/doctors/prerevision`,
                toFormData(this.doctor_prerevision_data),
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.doctor_prerevision_dialog = false;
                  this.getTableData();
                } else {
                  console.log(resp.data.message);
                }

                this.doctor_prerevision_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    noteDialog(item) {
      this.rs_service_notes = null;
      this.item = item;
      this.note_data = {
        id: null,
        rs_service_note: null,
        rs_service_id: item.id,
        folio_full: item.folio_full,
        doctor_id: item.doctor_id,
        doctor_name: item.doctor_name,
        insured_full_name: item.insured_full_name,
      };
      this.note_dialog_loading = true;
      this.note_dialog = true;

      Axios.post(
        `${URL_API}/rss/services/notes`,
        {
          rs_service_id: item.id,
        },
        headersToken(this.login.token)
      ).then((resp) => {
        this.rs_service_notes = resp.data.data;
        this.note_dialog_loading = false;
      });
    },
    noteSubmit() {
      if (this.$refs.note_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma agregar nota administrativa?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.note_dialog_loading = true;
              Axios.post(
                `${URL_API}/rss/services/notes/save`,
                this.note_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.item.rs_service_notes_total++;
                  this.note_dialog = false;
                } else {
                  console.log(resp.data.message);
                }

                this.note_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    billClean() {
      if (!this.service_payment_data.bill) {
        this.service_payment_data.legal_name = "";
        this.service_payment_data.code_tax = "";
        this.service_payment_data.legal_zip = "";
        this.service_payment_data.fiscal_regime_id = null;
        this.service_payment_data.fiscal_use_id = null;
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(`${URL_API}/insurances`, headersToken(this.login.token)).then(
      (resp) => {
        this.insurances = resp.data.data;
        this.insurances_loading = false;
      }
    );
    Axios.get(
      `${URL_API}/catalog?name=fiscal_regimes&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.fiscal_regimes = resp.data.data;
      this.fiscal_regimes_loading = false;
    });
    Axios.get(
      `${URL_API}/catalog?name=fiscal_uses&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.fiscal_uses = resp.data.data;
      this.fiscal_uses_loading = false;
    });
    Axios.get(
      `${URL_API}/catalog?name=fiscal_method_payments&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.fiscal_method_payments = resp.data.data;
      this.fiscal_method_payments_loading = false;
    });
    Axios.get(
      `${URL_API}/providers/type/4`,
      headersToken(this.login.token)
    ).then((response) => {
      this.providers = response.data.data;
      this.providers_loading = false;
    });
    Axios.get(
      `${URL_API}/catalog?name=banks&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.banks = resp.data.data;
      this.banks_loading = false;
    });

    const dateTime = dateTimeNow();
    this.table_search_options.start_date = dateTime.substring(0, 10);
    this.table_search_options.end_date = dateTime.substring(0, 10);

    this.getTableData();
  },
};
</script>